@import '../../styles/variables.styl';

.footer {
  position: relative;
  height: 447px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: -9999em;
    bottom: 0;
    left: -9999em;
    z-index: -1;
    background: $dark_grey;
  }

  &__left {
    padding-top: 94px;
    width: 600px;
    display: inline-block;
    vertical-align: top;
  }

  &__policy {
    margin-top: 40px;
    display: block;

    a {
      color: $white_50;
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
      text-decoration: none;
      transition: color 0.25s ease-in-out;

      &:hover {
        color: $red;
      }
    }
  }

  &__right {
    padding-top: 94px;
    padding-left: 210px;
    width: 360px;
    display: inline-block;
    vertical-align: top;
  }

  &__bottom {
    padding-top: 26px;
  }

  @media $mqTablet {
    padding-bottom: 50px;
    height: auto;
    text-align: center;

    &__left {
      padding-top: 50px;
    }

    &__policy {
      margin-top: 25px;
    }

    &__right {
      display: none;
    }
  }

  @media $mqMobile {
    padding-bottom: 30px;
    height: auto;

    &__right {
      padding: 45px 0 0 0;
      width: 100%;
      display: block;
    }
  }
}
